<template>
  <div class="home">
    <el-carousel :height="bannerHeight + 'px'">
      <el-carousel-item v-for="(item, i) in bannerImages" :key="i">
        <img :src="item" alt="">
      </el-carousel-item>
    </el-carousel>
    <br />
    <core-bar></core-bar>
    <br />
    <function-tab></function-tab>
    <br />
    <advantage></advantage>
    <!--div class="float-box">
<img  src="/static/img/p1.png"  style="width:100%" onmouseover="this.src='/static/img/p2.png'" onmouseout="this.src='/static/img/p1.png'"/>
</div-->

  </div>
</template>

<script>
import CoreBar from './components/CoreBar'
import FunctionTab from './components/FunctionTab'
import Advantage from './components/Advantage'

export default {
  name: 'index',
  components: {
    CoreBar,
    FunctionTab,
    Advantage
  },
  data() {
    return {
      bannerImages: ['/static/img/banner0.png', '/static/img/banner4.png', '/static/img/banner5.png', '/static/img/banner6.png'],
      bannerHeight: 600
    }
  },

  mounted() {
    let screenWidth = window.innerWidth
    this.getSize(screenWidth)
    window.onresize = () => {
      screenWidth = window.innerWidth
      this.getSize(screenWidth)
    }
  },

  methods: {
    getSize(screenWidth) {
      if (screenWidth < 1200) {
        this.bannerHeight = (650 * screenWidth) / 1920
      } else {
        this.bannerHeight = (650 * screenWidth) / 1920
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .el-carousel__item {
    height: max-content;
    img {
      width: 100%;
    }
  }
}
.float-box{
width:140px;
height:140px;
position:fixed;
right:.2rem;
top:300px;
padding:0 !important;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
object-fit:none;
z-index:9999;
}
.float-box:hover {
width:100%;
height:475px;
}
</style>
