<template>
  <el-card class="advantage-item" :body-style="{ padding: '15px' }">
    <el-space wrap>
      <img :src="img" />
      <div class="title">{{ title }}</div>
    </el-space>
    <p class="content">{{ content }}</p>
  </el-card>
</template>
<script>
export default {
  props: {
    img: String,
    title: String,
    content: String
  }
}
</script>

<style lang="scss" scoped>
.advantage-item {
  height: 160px;
  text-align: left;
  img {
    width: 60px;
    height: 60px;
  }

  .title {
    height: 60px;
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
  }

  .content {
    color: #717171;
    font-size: 12px;
  }
}
</style>
