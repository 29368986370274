<template>
  <el-card class="core-bar" :class="{ isMobile }">
    <el-row style="max-width: 1400px; margin: 0 auto">
      <el-col :span="8">
        <el-space wrap>
          <div class="icon icon1"></div>
          <div>
            <h4>海量模型</h4>
            <p>全球顶流策略，一键启动</p>
          </div>
        </el-space>
      </el-col>
      <el-col :span="8">
        <el-space wrap>
          <div class="icon icon2"></div>
          <div>
            <h4>私人定制</h4>
            <p>按你需求，私人定制</p>
          </div>
        </el-space>
      </el-col>
      <el-col :span="8">
        <el-space wrap>
          <div class="icon icon3"></div>
          <div>
            <h4>专属客服</h4>
            <p>一对一指导培训</p>
          </div>
        </el-space>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: 'CoreBar'
}
</script>

<style lang="scss" scoped>
.core-bar {
  text-align: center;
  p {
    font-size: 12px;
  }
  .el-space {
    text-align: left;
  }

  .icon {
    width: 60px;
    height: 60px;
  }

  .icon1 {
    background: url('/static/img/model.png') no-repeat;
    background-size: 100% 100%;
  }

  .icon2 {
    background: url('/static/img/define.png') no-repeat;
    background-size: 100% 100%;
  }

  .icon3 {
    background: url('/static/img/server.png') no-repeat;
    background-size: 100% 100%;
  }
}

.isMobile.core-bar {
  .el-col {
    .el-space {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .icon {
        width: 30px;
        height: 30px;
      }

      h4 {
        font-size: 16px;
        padding: 0;
        margin: 0;
      }

      p {
        font-size: 10px;
        margin: 0;
      }
    }
  }
}
</style>
