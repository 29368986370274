<template>
  <el-card class="tab-frame" :class="{ isMobile }">
    <div class="header">
      <img :src="img" />
      <div class="title">{{ title }}</div>
    </div>
    <div class="content">{{ content }}</div>
  </el-card>
</template>

<script>
export default {
  name: 'TabFrame',
  props: {
    img: String,
    title: String,
    content: String
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 200px;
  color: #4f5a65;
  background: #ffffff;
  text-align: center;
  font-size: 22px;

  img {
    width: 86px;
    height: 86px;
  }
}
.content {
  height: 200px;
}
.tab-frame {
  display: inline-block;
  margin: 20px;
  max-width: 300px;
  height: 400px;
  -webkit-transition: all 0.6s ease-in;
  -moz-transition: all 0.6s ease-in;
  -ms-transition: all 0.6s ease-in;
  -o-transition: all 0.6s ease-in;
  transition: all 0.6s ease-in;
  overflow: hidden;
}

.tab-frame:hover {
  cursor: pointer;
  -webkit-transform: translate(0, -20px);
  -moz-transform: translate(0, -20px);
  -ms-transform: translate(0, -20px);
  -o-transform: translate(0, -20px);
  transform: translate(0, -20px);
}

@keyframes passing_4500 {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  33.33333% {
    transform: translateX(-100%);
    opacity: 0;
  }
  66.66667% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.isMobile.tab-frame {
  height: 250px;
  margin: 10px;
  .content {
    height: 100px;
    font-size: 12px;
  }

  .title {
    font-size: 18px;
  }

  .tab-frame {
    height: max-content;
    margin: 10px 10px;
    padding: 20px 0;
  }

  .header {
    height: 130px;
  }
}
</style>
