<template>
  <el-card class="function-tab" :class="{ isMobile }">
    <div class="header">
      <h3>强大的量化框架为您提供技术支撑</h3>
      <p>实盘、模拟、回测、预警、分析，聚客量化交易平台实现一站式服务</p>
    </div>
    <br />
    <el-space>
      <el-row>
        <el-col :span="6" :xs="12">
          <tab-frame
            img="/static/img/tile-img-12.png"
            title="优选策略"
            content="经过数十年市场验证和开发经验，我们精选出大量全球优质的算法交易模型，无需编程，即可一键启用"
          ></tab-frame>
        </el-col>
        <el-col :span="6" :xs="12">
          <tab-frame
            img="/static/img/tile-img-22.png"
            title="精准回测"
            content="我们提供高质量Tick级精准数据，强大的多任务组合回测引擎，急速验证策略和策略优化"
          ></tab-frame>
        </el-col>
        <el-col :span="6" :xs="12">
          <tab-frame
            img="/static/img/tile-img-32.png"
            title="指标工具"
            content="我们提供多套专业的技术分析指标和辅助盯盘工具，让盯盘不再辛苦"
          ></tab-frame>
        </el-col>
        <el-col :span="6" :xs="12">
          <tab-frame
            img="/static/img/tile-img-42.png"
            title="实盘交易"
            content="国内期货、沪深A股、美股港股、衍生品交易，一套系统，触之可达"
          ></tab-frame>
        </el-col>
      </el-row>
    </el-space>
  </el-card>
</template>
<script>
import TabFrame from './TabFrame.vue'

export default {
  components: {
    TabFrame
  }
}
</script>

<style lang="scss" scoped>
.function-tab {
  text-align: center;
}
</style>
