<template>
  <el-card class="advantage" :class="{ isMobile }">
    <div class="header">
      <h3>为什么选择聚客量化交易系统</h3>
      <p>
        聚客量化交易团队，拥有超过5000例量化策略开发经验和强大的IT工程师团队，致力于打造量化交易3.0，以降低交易者的量化门槛
      </p>
    </div>
    <br />
    <div class="content">
      <el-row :gutter="10" style="max-width: 1400px; margin: 0 auto">
        <el-col :span="6" :xs="24">
          <advantage-item
            img="/static/img/adv-11.png"
            title="坚持用户至上"
            content="追求极致的用户体验，快速响应用户需求，为用户最大化创造价值"
          ></advantage-item>
        </el-col>
        <el-col :span="6" :xs="24">
          <advantage-item
            img="/static/img/adv-12.png"
            title="保障策略安全"
            content="所有用户量化交易均为用户本地运行，交易账户和策略不中继公司服务器，任何人都无法获取您的策略"
          ></advantage-item>
        </el-col>
        <el-col :span="6" :xs="24">
          <advantage-item
            img="/static/img/adv-13.png"
            title="提供Tick级数据"
            content="我们提供超过10年以上tick级数据，让您的策略研究更接近真实"
          ></advantage-item>
        </el-col>
        <el-col :span="6" :xs="24">
          <advantage-item
            img="/static/img/adv-14.png"
            title="海量优选策略"
            content="平台提供数十种不同类型的优质策略模型,在您没有好的思路时，可直接启用"
          ></advantage-item>
        </el-col>
      </el-row>
      <el-row
        :gutter="10"
        style="max-width: 1400px; margin: 0 auto; margin-top: 10px"
      >
        <el-col :span="6" :xs="24">
          <advantage-item
            img="/static/img/adv-15.png"
            title="开源的量化框架"
            content="Python语言支持，可以将您的想法和思路写入在聚客量化平台"
          ></advantage-item>
        </el-col>
        <el-col :span="6" :xs="24">
          <advantage-item
            img="/static/img/adv-16.png"
            title="一对一专属服务"
            content="平台提供一对一专业使用指导，直到用户可熟练使用各项功能"
          ></advantage-item>
        </el-col>
        <el-col :span="6" :xs="24">
          <advantage-item
            img="/static/img/adv-17.png"
            title="指标和辅助工具"
            content="平台内置多款优质指标和盯盘工具，可实现多窗口监控和微信信号推送"
          ></advantage-item>
        </el-col>
        <el-col :span="6" :xs="24">
          <advantage-item
            img="/static/img/adv-18.png"
            title="私人定制服务"
            content="如果您不懂得如何编写策略模型，平台可安排专属IT工程师为您提供定制服务"
          ></advantage-item>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import AdvantageItem from './AdvantageItem.vue'
export default {
  components: {
    AdvantageItem
  },

  mounted() {}
}
</script>

<style lang="scss" scoped>
.advantage {
  text-align: center;

  .content {
    padding: 0 60px;
  }
}

.isMobile {
  .content {
    padding: 0 10px;

    .el-col-xs-24 {
      margin-bottom: 20px;
    }
  }
}
</style>
